angular.module('mavibayrak')
  .config(function ($stateProvider, $urlRouterProvider, $httpProvider, $ionicConfigProvider) {
    // Don't prefetch templates
    $ionicConfigProvider.templates.maxPrefetch(0);

    function calcViews(type, obj) {
      var types = type + 's';
      if (type == 'beach') types = 'beaches';
      var key = ('tab-' + types);
      var views = {};
      views[key] = obj;

      return views;
    }

    function questionsRoute(type) {
      return {
        url: '/' + type + '/questions/:auditId/:page',
        views: calcViews(type, {
          templateUrl: 'templates/questions.html',
          controller: 'QuestionsCtrl'
        }),
        resolve: {
          questionsList: function (Questions) {
            return Questions.populate();
          }
        }
      };
    }

    function auditTeamRoute(type) {
      return {
        url: '/audit-team/:auditId',
        views: calcViews(type, {
          templateUrl: 'templates/audit-team.html',
          controller: 'AuditTeamCtrl'
        }),
        resolve: {
          questionsList: function (Questions) {
            return Questions.populate();
          }
        }
      };
    }

    function evaluationRoute(type) {
      return {
        url: '/evaluation/:auditId',
        views: calcViews(type, {
          templateUrl: 'templates/evaluation.html',
          controller: 'EvaluationCtrl'
        }),
        resolve: {
          questionsList: function (Questions) {
            return Questions.populate();
          }
        }
      };
    }

    function emailsRoute(type) {
      return {
        url: '/emails/:auditId',
        views: calcViews(type, {
          templateUrl: 'templates/emails.html',
          controller: 'EmailsCtrl'
        }),
        resolve: {
          beachesList: function (Beaches, Questions, $q) {
            return $q.all([Beaches.populate(), Questions.populate()]);
          }
        }
      };
    }

    $stateProvider

      .state('login', {
        url: '/login',
        templateUrl: 'templates/login.html',
        controller: 'LoginCtrl'
      })

      // setup an abstract state for the tabs directive
      .state('tab', {
        url: "/tab",
        abstract: true,
        templateUrl: "templates/tabs.html"
      })

      // Each tab has its own nav history stack:

      .state('tab.beaches', {
        url: '/beaches',
        cache: false,
        views: {
          'tab-beaches': {
            templateUrl: 'templates/tab-beaches.html',
            controller: 'BeachesCtrl'
          }
        },
        resolve: {
          beachesList: function (Beaches, Cities, Towns, $q) {
            return $q.all([Beaches.populate(), Cities.populate(), Towns.populate()]);
          }
        }
      })

      .state('tab.beach', {
        url: '/beach/:beachId',
        views: {
          'tab-beaches': {
            templateUrl: 'templates/beach.html',
            controller: 'BeachCtrl'
          }
        },
        resolve: {
          townsList: function (Beaches, Cities, Towns, $q) {
            return $q.all([Beaches.populate(), Cities.populate(), Towns.populate()]);
          }
        }
      })

      .state('tab.edit_beach', {
        url: '/beach/edit/:auditId',
        views: {
          'tab-beaches': {
            templateUrl: 'templates/beach.html',
            controller: 'BeachCtrl'
          }
        },
        resolve: {
          townsList: function (Beaches, Cities, Towns, $q) {
            return $q.all([Beaches.populate(), Cities.populate(), Towns.populate()]);
          }
        }
      })

      .state('tab.yachts', {
        url: '/yachts',
        cache: false,
        views: {
          'tab-yachts': {
            templateUrl: 'templates/tab-yachts.html',
            controller: 'YachtsCtrl'
          }
        },
        resolve: {
          yachtsList: function (Yachts, Cities, Towns, $q) {
            return $q.all([Yachts.populate(), Cities.populate(), Towns.populate()]);
          }
        }
      })

      .state('tab.yacht', {
        url: '/yacht/:yachtId',
        views: {
          'tab-yachts': {
            templateUrl: 'templates/yacht.html',
            controller: 'YachtCtrl'
          }
        },
        resolve: {
          townsList: function (Yachts, Cities, Towns, $q) {
            return $q.all([Yachts.populate(), Cities.populate(), Towns.populate()]);
          },
          marinasList: function (Marinas, Cities, $q) {
            return $q.all([Marinas.populate(), Cities.populate()]);
          }
        }
      })

      .state('tab.marinas', {
        url: '/marinas',
        cache: false,
        views: {
          'tab-marinas': {
            templateUrl: 'templates/tab-marinas.html',
            controller: 'MarinasCtrl'
          }
        },
        resolve: {
          marinasList: function (Marinas, Cities, Towns, $q) {
            return $q.all([Marinas.populate(), Cities.populate(), Towns.populate()]);
          }
        }
      })

      .state('tab.marina', {
        url: '/marina/:marinaId',
        views: {
          'tab-marinas': {
            templateUrl: 'templates/marina.html',
            controller: 'MarinaCtrl'
          }
        },
        resolve: {
          townsList: function (Marinas, Cities, Towns, $q) {
            return $q.all([Marinas.populate(), Cities.populate(), Towns.populate()]);
          }
        }
      })

      .state('tab.edit_marina', {
        url: '/marina/edit/:auditId',
        views: {
          'tab-marinas': {
            templateUrl: 'templates/marina.html',
            controller: 'MarinaCtrl'
          }
        },
        resolve: {
          townsList: function (Marinas, Cities, Towns, $q) {
            return $q.all([Marinas.populate(), Cities.populate(), Towns.populate()]);
          }
        }
      })

      .state('tab.settings', {
        url: '/settings',
        cache: false,
        views: {
          'tab-settings': {
            templateUrl: 'templates/tab-settings.html',
            controller: 'SettingsCtrl'
          }
        },
        resolve: {
          marinasList: function (Marinas, Cities, Towns, $q) {
            return $q.all([Marinas.populate(), Cities.populate(), Towns.populate()]);
          }
        }
      })


      .state('tab.questions_beach', questionsRoute('beach'))
      .state('tab.auditTeam_beach', auditTeamRoute('beach'))
      .state('tab.evaluation_beach', evaluationRoute('beach'))
      .state('tab.emails_beach', emailsRoute('beach'))

      .state('tab.questions_yacht', questionsRoute('yacht'))
      .state('tab.auditTeam_yacht', auditTeamRoute('yacht'))
      .state('tab.evaluation_yacht', evaluationRoute('yacht'))
      .state('tab.emails_yacht', emailsRoute('yacht'))

      .state('tab.questions_marina', questionsRoute('marina'))
      .state('tab.auditTeam_marina', auditTeamRoute('marina'))
      .state('tab.evaluation_marina', evaluationRoute('marina'))
      .state('tab.emails_marina', emailsRoute('marina'));



    // if none of the above states are matched, use this as the fallback
    // $urlRouterProvider.otherwise('/tab/beaches'); --> Infinite loop
    // See bug: https://github.com/angular-ui/ui-router/issues/600
    $urlRouterProvider.otherwise( function($injector) {
      var $state = $injector.get("$state");
      $state.go("tab.beaches");
    });

    // Authentication
    $httpProvider.interceptors.push('AuthInterceptor');

  });
