angular.module('mavibayrak')
  .controller('QuestionsCtrl', function ($scope, $stateParams, $state, $ionicPopup, $ionicPopover, $ionicActionSheet, $ionicHistory,
                                         Questions, Audits, Validator, Utils) {
    var audit = Audits.getStored($stateParams.auditId);
    if (!audit) {
      $ionicHistory.nextViewOptions({historyRoot: true});
      $ionicPopup.alert({ template: 'Kayıt bulunamadı. id: ' + $stateParams.auditId})
        .then(function () { $state.go('tab.beaches'); });
      return;
    }
    var type = Audits.getType(audit);
    switch (type) {
      case 'beach':
        $scope.entity = audit.beach;
        break;
      case 'marina':
        $scope.entity = audit.marina;
        break;
      case 'yacht':
        $scope.entity = audit.yacht;
        break;
    }

    $scope.answers = audit.answers ? audit.answers.answers : {};
    $scope.notes = audit.answers ? audit.answers.notes : {};
    $scope.photos = audit.photos && audit.photos[$stateParams.page] ? audit.photos[$stateParams.page] : [];
    $scope.page = $stateParams.page;
    $scope.questions = Questions.get(type, $stateParams.page);
    $scope.group = Questions.getGroup(type, $stateParams.page);
    $scope.hiddenQ = audit.hidden || [];

    $scope.isHidden = function (question) {
      var id = parseInt(question.id);
      return $scope.hiddenQ.indexOf(id) != -1;
    };

    var gotoNextPage = function () {
      if (Questions.hasNext(type, $stateParams.page)) {
        $state.go('tab.questions_' + type, {
          auditId: audit.id,
          page: parseInt($stateParams.page) + 1
        });
      } else {
        $state.go('tab.auditTeam_' + type, {
          auditId: audit.id
        });
      }
    };

    $scope.nextPage = function () {
      var photos = {};
      photos[$stateParams.page] = $scope.photos;
      var res = Audits.updateAnswers(audit.id, $scope.questions, {
        answers: {
          answers: $scope.answers,
          notes: $scope.notes
        },
        photos: photos,
        hidden: $scope.hiddenQ
      });

      if (!res) return;

      audit = Audits.getStored($stateParams.auditId);

      Validator.isPageValid(audit, $stateParams.page).then(
        gotoNextPage,
        function (firstFailedQuestion) {
          $ionicPopup.confirm({
            title: 'Tüm sorular cevaplanmadı',
            template: "'" + firstFailedQuestion + "' sorusu cevaplanmadı. Devam etmek istiyor musunuz?"
          }).then(function (res) {
            if (res) gotoNextPage();
          });
        }
      );

    };

    $scope.getPhoto = function (photoPromise) {
      photoPromise.then(function (imgSrc) {
        $scope.photos.push({
          src: imgSrc
        });
      });
    };

    $scope.check = function (question) {
      if ($scope.answers[question.id] == 1) {
        delete $scope.answers[question.id];
      } else {
        $scope.answers[question.id] = 1;
      }
    };

    $scope.uncheck = function (question) {
      if ($scope.answers[question.id] == 2) {
        delete $scope.answers[question.id];
      } else {
        $scope.answers[question.id] = 2;
      }
    };

    $scope.note = function (question) {
      // Create isolated scope
      var $newScope = $scope.$new(true);
      $newScope.data = {
        note: $scope.notes[question.id]
      };

      $ionicPopup.show({
        title: 'Notlar',
        scope: $newScope,
        template: '<label class="item item-input"> <textarea placeholder="Notlar" ng-model="data.note"></textarea> </label>',
        buttons: [
          {
            text: 'İptal',
            type: 'button-default'
          },
          {
            text: 'Tamam',
            type: 'button-positive',
            onTap: function () {
              if (!$newScope.data.note) {
                delete $scope.notes[question.id];
                return false;
              }
              if (!$scope.answers[question.id]) {
                $scope.answers[question.id] = 2;
              }
              $scope.notes[question.id] = $newScope.data.note;
            }
          }
        ]
      }).finally(function () {
        $newScope.$destroy();
      });
    };

    $scope.openPopover = function ($event, question) {
      // Set options and set if they are checked or not
      $scope.options = question.options.map(function (item) {
        if ($scope.answers[question.id] && $scope.answers[question.id].indexOf(item.value) != -1)
          item.checked = true;
        return item;
      });

      $ionicPopover.fromTemplateUrl('templates/multiselect.html', {
        scope: $scope
      }).then(function (popover) {
        $scope.popover = popover;
        $scope.popover.show($event);
      });

      // Watch doesn't work for some reason
      $scope.cbClick = function () {
        var selectedOptions = [];
        $scope.options.filter(function (item) {
          if (item.checked) {
            selectedOptions.push(item.value);
          }
        });
        $scope.answers[question.id] = selectedOptions;
      };
    };

    $scope.photoActionSheet = function (photo) {
      $ionicActionSheet.show({
        destructiveText: 'Fotoğrafı Sil',
        cancelText: 'İptal',
        destructiveButtonClicked: function () {
          var index = $scope.photos.indexOf(photo);
          $scope.photos.splice(index, 1);
          var photosData = {};
          photosData[$stateParams.page] = $scope.photos;
          Audits.update(audit.id, {
            photos: photosData
          });
          return true;
        }
      });
    };

    $scope.$watchCollection('answers', function (newAnswers) {
      // Hidden'ı boşalt
      for (var key1 in $scope.questions) {
        //noinspection JSUnresolvedFunction
        if (!$scope.questions.hasOwnProperty(key1)) continue;
        var qId = parseInt($scope.questions[key1].id);
        var index = $scope.hiddenQ.indexOf(qId);
        if (index != -1) $scope.hiddenQ.splice(index, 1);
      }

      // Show hide questions
      for (var key in $scope.questions) {
        //noinspection JSUnresolvedFunction
        if (!$scope.questions.hasOwnProperty(key)) continue;

        var question = $scope.questions[key];
        if (!question.rules || question.rules.length == 0) continue;
        if (!angular.isArray(question.rules)) question.rules = [question.rules];

        question.rules.map(function (rule) {
          rule.questions.map(function (questionId) {
            questionId = parseInt(questionId);
            if (newAnswers[question.id] != rule.answer && $scope.hiddenQ.indexOf(questionId) == -1) {
              $scope.hiddenQ.push(questionId);
            }
          });
        });
      }
    });

    $scope.cancelAudit = function () {
      Utils.cancelAudit(audit);
    };
  });
