angular.module('mavibayrak')
  .factory('LoggerService', function () {
    return {
      print: printStackTrace
    };
  })
  .provider("$exceptionHandler", {
    $get: function (errorLogService) {
      return errorLogService;
    }
  })
  .factory("errorLogService", function ($log, $window, $injector, LoggerService, appConfig) {

    function log(exception, cause) {
      // Restore default logger
      $log.error.apply($log, arguments);

      try {
        var $http = $injector.get('$http');
        var errorMessage = exception.toString();
        var stackTrace = LoggerService.print({e: exception});

        var data = {
          user: $window.localStorage.email || "",
          errorUrl: $window.location.href,
          errorMessage: errorMessage,
          stackTrace: stackTrace,
          cause: ( cause || "" )
        };

        if (navigator.onLine) {
          $http.post(appConfig.endpoint('clilog'), data);
        }

        var $alert = $injector.get('$ionicPopup').alert;
        $alert({
          title: 'Hata oluştu',
          subTitle: navigator.onLine ? 'Oluşan hata rapor edildi' : 'Çevrimiçi olmadığınız için hata rapor edilemedi.',
          template: errorMessage
        });
      } catch (loggingError) {
        alert('Hata bildirilirken hata oluştu');
        $log.warn("Error logging failed");
        $log.log(loggingError);
      }

    }

    // Return the logging function.
    return log;

  });
