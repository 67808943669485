angular.module('mavibayrak')
  .controller('BeachesCtrl', function ($scope, $filter, $ionicPopup, $state, Beaches, Audits) {
    var beaches = Beaches.all();

    $scope.search = {
      name: '',
      city_id: '',
      town_id: ''
    };

    $scope.cities = Beaches.getCities();

    $scope.$watchCollection('search', function (newSearch, oldSearch) {
      if (newSearch.city_id != oldSearch.city_id) {
        $scope.towns = newSearch.city_id ? Beaches.getTownsOfCity(newSearch.city_id) : [];
      }
      //noinspection JSUnresolvedVariable
      if (newSearch.name.trim().length == 0 && !newSearch.city_id) {
        $scope.items = [];
      } else {
        var filter = {name: newSearch.name};
        if (newSearch.city_id) filter['city_id'] = newSearch.city_id;
        if (newSearch.town_id) filter['town_id'] = newSearch.town_id;
        var items = $filter('filter')(beaches, filter);
        $scope.items = $filter('limitTo')(items, 10);
      }
    });

    $scope.gotoBeach = function (beach) {
      var beachId = beach.id;
      var unsavedData = Audits.unsavedData('beach', beachId);
      if (unsavedData.length > 0) {
        var beaches = unsavedData.map(function (item) {
          return item.beach.name;
        }).join(', ');
        $ionicPopup.confirm({
          title: 'Yarım Kalmış Kayıt',
          template: beaches + ' için tamamlanmamış kaydınız bulunmaktadır.<br><br>' +
            'Devam ederseniz bu bilgiler silinecektir.<br><br>' +
            'Devam etmek istiyor musunuz?'
        }).then(function (confirmed) {
          if (confirmed) {
            $state.go('tab.beach', {beachId: beachId});
          }
        });
      } else {
        $state.go('tab.beach', {beachId: beachId});
      }
    }
  });