angular.module('mavibayrak')
  .factory('Utils', function (Audits, $state, $ionicHistory, $ionicPopup) {
    return {
      trSorter: function (a, b) {
        var name1 = a.name;
        var name2 = b.name;
        var search = ['Ç', 'İ', 'Ş', 'Ğ', 'Ö', 'Ü', 'ç', 'ı', 'ş', 'ğ', 'ö', 'ü'];
        var replace = ['C', 'I', 'S', 'G', 'O', 'U', 'c', 'i', 's', 'g', 'o', 'u'];
        for (var i = 0; i < search.length; i++) {
          name1 = name1.replace(search[i], replace[i]);
          name2 = name2.replace(search[i], replace[i]);
        }
        if (name1 > name2) {
          return 1;
        }
        if (name1 < name2) {
          return -1;
        }
        return 0;
      },
      cancelAudit: function (audit) {
        var type = Audits.getType(audit);
        var route;
        switch(type) {
          case 'beach':
            route = 'tab.beaches';
            break;
          case 'yacht':
            route = 'tab.yachts';
            break;
          case 'marina':
            route = 'tab.marinas';
            break;
        }
        $ionicPopup.confirm({
          title: 'Denetim İptali',
          template: "Denetim kaydı ve bu denetime ait fotoğraflar varsa silinecektir. Devam etmek istiyor musunuz?"
        }).then(function (res) {
          if (res) {
            Audits.removeSavedData(audit);
            $ionicHistory.nextViewOptions({
              historyRoot: true
            });
            $state.go(route);
          }
        });
      }
    }
  });