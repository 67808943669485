angular.module('mavibayrak')
  .factory('Towns', function ($http, $localstorage, $q, appConfig, MyCache) {
    var towns;

    return {
      populate: function (force) {
        if (navigator.onLine && (force || MyCache.check('lutown', 120))) {
          return $http.get(appConfig.endpoint('towns')).then(function (response) {
            towns = response.data;
            $localstorage.setObject('townsData', towns);
            MyCache.save('lutown');
            return towns;
          });
        } else {
          towns = $localstorage.getObject('townsData');
          return $q.when(towns);
        }
      },
      all: function () {
        return towns;
      },
      get: function (id) {
        var town;
        towns.forEach(function (element) {
          if (element.id == id) {
            town = element;
            return false;
          }
        });

        return town;
      }
    };
  });