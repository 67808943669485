angular.module('mavibayrak')
  .controller('SettingsCtrl', function ($scope, AuthService, $localstorage, $ionicPopup, $state, $q, Audits) {
    var updatePendingAudits = function () {
      $scope.pendingAudits = Audits.savedData();
    };

    $scope.unsavedAudits = Audits.unsavedData();

    updatePendingAudits();

    $scope.sendPendingAudits = function () {
      if (!navigator.onLine) {
        $ionicPopup.alert({
          title: 'Bağlantı hatası',
          template: 'Internet bağlantısı bulunamadı, lütfen bağlantı sağlandıktan sonra deneyiniz.'
        });
        return;
      }

      var pendingAudits = Audits.savedData();
      var promises = [];

      var loadingPopup = $ionicPopup.show({
        title: "Sunucu Bağlantısı Kuruluyor",
        template: 'Form bilgileri sunucuya gönderilirken lütfen bekleyiniz...'
      });

      for (var i = 0; i < pendingAudits.length; i++) {
        var promise = Audits.sendToServer(pendingAudits[i]);
        promises.push(promise);
      }

      $q.all(promises).finally(function () {
        updatePendingAudits();
        loadingPopup.close();
      });
    };

    $scope.logout = function () {
      AuthService.logout();
    };

    $scope.resetApp = function () {
      $ionicPopup.confirm({
        title: 'Uygulamayı sıfırlamak istediğinizden emin misiniz?',
        template: 'Tarayıcının hafızasındaki tüm bilgiler silinecektir.<br><br>Devam etmek istiyor musunuz?'
      }).then(function (confirmed) {
        if (confirmed) {
          $localstorage.clearAll();
          $state.go('login');
        }
      });
    };
  });

