angular.module('mavibayrak')
  .factory('Questions', function ($http, $localstorage, $q, $timeout, appConfig, MyCache) {
    var questions, groups;

    var fixPageParam = function (page, type) {
      page = parseInt(page);
      if (type == 'yacht') page += 4;
      if (type == 'marina') page += 8;
      return page;
    };

    return {
      populate: function (force) {
        if (navigator.onLine && (force || MyCache.check('luq', 120))) {
          return $http.get(appConfig.endpoint('questions')).then(function (response) {
            var data = response.data;
            questions = data.questions;
            groups = data.groups;
            $localstorage.setObject('questionsData', questions);
            $localstorage.setObject('questionGroupsData', groups);
            MyCache.save('luq');
            return [questions, groups];
          });
        } else {
          // Fix for: Error: $rootScope:inprog Action Already In Progress
          questions = $localstorage.getObject('questionsData');
          groups = $localstorage.getObject('questionGroupsData');
          return $q.when([questions, groups]);
        }
      },
      get: function (type, page) {
        page = fixPageParam(page, type);
        return questions.filter(function (item) {
          return item.entity == type && item.question_group_id == page;
        });
      },
      getById: function (id) {
        var question;
        for (var i = 0; i < questions.length; i++) {
          var q = questions[i];
          if (q.id == id) {
            question = q;
            break;
          }
        }
        return question;
      },
      getGroup: function (type, id) {
        id = parseInt(id);
        for (var i = 0; i < groups.length; i++) {
          var item = groups[i];
          if (type == 'beach' && item.id == id) {
            return item;
          } else if (type == 'yacht' && item.id == (id + 4)) {
            return item;
          } else if (type == 'marina' && item.id == (id + 8)) {
            return item;
          }
        }

        throw "Group not found: " + type + ', ' + id
      },
      hasNext: function (type, page) {
        page = fixPageParam(page, type);
        var found = false;
        questions.map(function (item) {
          if (item.entity == type && item.question_group_id == (parseInt(page) + 1)) found = true;
        });
        return found;
      },
      getAll: function () {
        return questions;
      }
    }
  });