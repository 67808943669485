angular.module('mavibayrak')
  .controller('YachtsCtrl', function ($scope, $filter, $ionicPopup, $state, Yachts, Audits) {
    var yachts = Yachts.all();

    $scope.search = {
      name: '',
      city_id: '',
      town_id: ''
    };

    $scope.cities = Yachts.getCities();

    $scope.$watchCollection('search', function (newSearch, oldSearch) {
      if (newSearch.city_id != oldSearch.city_id) {
        $scope.towns = newSearch.city_id ? Yachts.getTownsOfCity(newSearch.city_id) : [];
      }
      //noinspection JSUnresolvedVariable
      if (newSearch.name.trim().length == 0 && !newSearch.city_id) {
        $scope.items = [];
      } else {
        var filter = {name: newSearch.name};
        if (newSearch.city_id) filter['city_id'] = newSearch.city_id;
        if (newSearch.town_id) filter['town_id'] = newSearch.town_id;
        var items = $filter('filter')(yachts, filter);
        $scope.items = $filter('limitTo')(items, 10);
      }
    });

    $scope.gotoYacht = function (yacht) {
      var yachtId = yacht.id;
      var unsavedData = Audits.unsavedData('yacht', yachtId);
      if (unsavedData.length > 0) {
        var yachts = unsavedData.map(function (item) {
          return item.yacht.name;
        }).join(', ');
        $ionicPopup.confirm({
          title: 'Yarım Kalmış Kayıt',
          template: yachts + ' için tamamlanmamış kaydınız bulunmaktadır.<br><br>' +
          'Devam ederseniz bu bilgiler silinecektir.<br><br>' +
          'Devam etmek istiyor musunuz?'
        }).then(function (confirmed) {
          if (confirmed) {
            $state.go('tab.yacht', {yachtId: yachtId});
          }
        });
      } else {
        $state.go('tab.yacht', {yachtId: yachtId});
      }
    };
  });