angular.module('mavibayrak')
  .factory('Audits', function ($localstorage, $http, $q, $ionicPopup, $injector, appConfig, MyCache) {
    var methods = ['Haberli', 'Habersiz'];
    var types = ['Ön Denetim', 'Sezon Denetimi', 'Takip Denetimi'];
    var results = [
      'Kriterlere Tam Uyumlu',
      'Bazı Ayrıntılar Eksik',
      '10 gün Süreyle İndirildi',
      'Sezon Süresince İptal',
      'Kriterler Büyük Oranda Eksik'
    ];

    var generateUUID = function(){
      var d = new Date().getTime();
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
      });
    };

    var updateJsonObject = function updateJsonObject(initial, update) {
      var result = angular.copy(initial);
      for (var prop in update) {
        if (!update.hasOwnProperty(prop)) continue;

        if ( Object.prototype.toString.call(update[prop]) == '[object Object]') {
          result[prop] = updateJsonObject(initial[prop] || {}, update[prop]);
        } else if (update[prop]) {
          result[prop] = update[prop];
        }
      }
      return result;
    };

    return {
      methods: methods,
      types: types,
      results: results,
      /**
       * Default audit data
       */
      newRecord: function () {
        var date = new Date();
        return {
          id: generateUUID(),
          status: 'draft',
          audit: {
            created_at: date.toISOString(),
            audit_method: methods[0],
            audit_type: types[0],
            result: '',
            notes: ''
          }
        };
      },

      /**
       * Localstorage'dan audit data bilgisi alınır.
       *
       * Eğer id gelirse edit ediliyor, id yoksa tek draft getirilir
       * Örnek yapı:
       *
       * {
       *   id: 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx',
       *   status: 'draft', // enum(draft, saved)
       *   audit: {
       *     created_at: '...',
       *     audit_method: '...', // enum(this.methods)
       *     audit_type: '...', // enum(this.types)
       *     result: '...', // enum(this.results)
       *     notes: '...'
       *   },
       *   beach: {
       *     ... // beach, yacht ya da marina 3'ünden biri olabilir. Data değişken.
       *   },
       *   answers: {
       *     answers: {~questionId: answer}, // questionId dinamik, answer array ya da string.
       *     notes: {~questionId: note}
       *   },
       *   auditTeam: [
       *     {
       *       type: 'audit_team',  // enum(audit_team, beach_staff)
       *       name: '...'
       *     }
       *   ],
       *   photos: {
       *     ~pageId: [
       *       { src: '... base64encoded photo data' }
       *     ]
       *   },
       *   emails: [...],
       *   hidden: [] // Gizlenen sorular
       * }
       *
       * @param id
       * @returns {boolean}
       */
      getStored: function (id) {
        var audits = $localstorage.getObject('audits');

        if (!audits) {
          throw "No items found in the repository!";
        }


        var found = false;
        audits.some(function (element) {
          if (element.id == id) {
            found = element;
            return true;
          }
        });

        return found;
      },
      getDraft: function (type) {
        var audits = $localstorage.getObject('audits');
        // Localstorage'da audits yoksa yaratalım
        if (!audits) {
          $localstorage.setObject('audits', []);
          return false;
        }

        var found = false;
        audits.some(function (element) {
          if (element.status == 'draft' && element[type] != undefined) {
            found = element;
            return true;
          }
        });

        return found;
      },
      /**
       * Yeni denetim kaydı ekle
       *
       * @param audit
       */
      insert: function (audit) {
        if (this.getStored(audit.id)) {
          this.update(audit.id, audit);
        }

        var audits = $localstorage.getObject('audits');
        audits.push(audit);
        $localstorage.setObject('audits', audits);
      },

      /**
       * Denetim kaydı güncelle. audits[x].audit'e data ekler
       *
       * @param id
       * @param data
       */
      update: function (id, data) {
        var audits = $localstorage.getObject('audits');

        var found = false;
        for (var i = 0; i < audits.length; i++) {
          var row = audits[i];
          if (row.id == id) {
            audits[i] = updateJsonObject(audits[i], data);
            found = true;
            break;
          }
        }

        if (!found) throw "Denetim kaydı bulunamadı. id: " + id;

        return $localstorage.setObject('audits', audits);
      },

      /**
       * Cevapları günceller. Audits.update'ten farklı olarak
       * boş cevapları siler
       *
       * @param id
       * @param questions
       * @param data
       */
      updateAnswers: function (id, questions, data) {
        var audit = this.getStored(id);

        if (audit.answers) {
          questions.map(function (question) {
            delete audit.answers.answers[question.id];
            delete audit.answers.notes[question.id];
          });
        }
        audit = updateJsonObject(audit, data);

        // Replace record
        var audits = $localstorage.getObject('audits');

        for (var i = 0; i < audits.length; i++) {
          var row = audits[i];
          if (row.id == id) {
            audits[i] = audit;
            break;
          }
        }

        return $localstorage.setObject('audits', audits);
      },

      /**
       * type türündeki denetim kayıtlarını getirir, exceptId id'sine sahip olmayan
       * tüm unsaved satayı döndürür.
       *
       * @param type
       * @param exceptId
       * @returns {*}
       */
      unsavedData: function (type, exceptId) {
        /**
         * Find a previously unsaved data
         * BUSSINESS RULES:
         * 1. There can be multiple audits in the same season (season == year for us)
         * 2. Only recover the unsaved data. Edit mode is not automatic, the user must select an
         *    audit and choose to edit it.
         * 3. Only one "draft" record at a time (for GC)
         */
        var audits = $localstorage.getObject('audits');
        // Localstorage'da audits yoksa yaratalım
        if (!audits) {
          $localstorage.setObject('audits', []);
          return false;
        }

        var me = this;
        return audits.filter(function (record) {
          //var year = new Date(Date.parse(record.created_at)).getFullYear();
          //var thisYear = new Date().getFullYear();
          // if(year != thisYear) return false;
          if (record.status != 'draft') return false; // Draft değilse geç

          if (type !== undefined) {
            if (me.getType(record) != type) return false; // Farklı entity ise geç
            if (record[type].id == exceptId) return false; // Kendisi ise geç
          }

          return true;
        });
      },

      savedData: function () {
        var audits = $localstorage.getObject('audits');
        if (!audits) {
          return [];
        }

        return audits.filter(function (record) {
          return record.status == 'saved';
        });
      },

      deleteUnsavedData: function (type) {
        var audits = $localstorage.getObject('audits');
        for (var i = 0; i < audits.length; i++) {
          var record = audits[i];
          if (record.status != 'draft') continue;
          if (this.getType(record) != type) continue;

          audits.splice(i, 1);
        }
        $localstorage.setObject('audits', audits);
      },

      /**
       * Removes the data after it's sent to the server
       */
      removeSavedData: function (audit) {
        var audits = $localstorage.getObject('audits');
        var index = audits.indexOf(audit);
        audits.splice(index, 1);

        $localstorage.setObject('audits', audits);
      },

      setStatusDraft: function (audit) {
        audit.status ='draft';
        this.update(audit.id, audit);
      },

      setStatusSaved: function (audit) {
        audit.status ='saved';
        this.update(audit.id, audit);
      },

      getType: function (audit) {
        if (audit.beach) return 'beach';
        if (audit.marina) return 'marina';
        if (audit.yacht) return 'yacht';
      },

      populateTeam: function (force) {
        if (navigator.onLine && (force || MyCache.check('luauditteam', 120))) {
          return $http.get(appConfig.endpoint('auditTeam')).then(function (response) {
            $localstorage.setObject('teamData', response.data);
            MyCache.save('luauditteam');
            return response.data;
          });
        } else {
          return $q.when($localstorage.getObject('teamData'));
        }
      },

      populateEmails: function (force) {
        if (navigator.onLine && (force || MyCache.check('luemails', 120))) {
          return $http.get(appConfig.endpoint('emails')).then(function (response) {
            $localstorage.setObject('emailsData', response.data);
            MyCache.save('luemails');
            return response.data;
          });
        } else {
          return $q.when($localstorage.getObject('emailsData'));
        }
      },

      sendToServer: function (audit) {
        var deferred = $q.defer(), me = this;

        $http
          .post(appConfig.endpoint('audit/save'), audit)
          .success(function (response) {
            if (response.success === true) {
              me.removeSavedData(audit);
              me.populateTeam(true);
              me.populateEmails(true);

              var map = {
                beach: 'Beaches',
                yacht: 'Yachts',
                marina: 'Marinas'
              };
              var modelClass = $injector.get(map[me.getType(audit)]);
              modelClass.populate(true);

              deferred.resolve();
            } else {
              $ionicPopup.alert({
                title: 'Hata Oluştu',
                template: response.errorMessage
              });
              deferred.reject();
            }
          })
          .error(function () {
            deferred.reject();
          });

        return deferred.promise;
      }
    }
  });