angular.module('mavibayrak')
  .factory('ExifRestorer', function () {
    /*
     * See http://www.perry.cz/files/ExifRestorer.js
     */

    //Based on MinifyJpeg
    //http://elicon.blog57.fc2.com/blog-entry-206.html

    //noinspection UnnecessaryLocalVariableJS
    var ExifRestorer = (function () {

      var ExifRestorer = {};

      /**
       * Public method'umuz bu, bunu kullanıyoruz.
       *
       * @param {String} sourceFile base64 encoded string
       * @param {String} targetFile base64 encoded string
       * @returns {String} base64 encoded string
       */
      ExifRestorer.restore = function (sourceFile, targetFile) {
        if (sourceFile.indexOf("data:image/jpeg;base64,") == -1) {
          throw "Wrong file format";
        }

        // TODO: İlk 128kb'ı oku (ref: http://code.flickr.net/2012/06/01/parsing-exif-client-side-using-javascript-2/)
        var segments = this.slice2Segments(sourceFile);

        var image = this.insertExif(targetFile, this.getExifArray(segments));

        return "data:image/jpeg;base64," + this.encode64(image);

      };


      /**
       * Tüm dosyayı segment'lerin böler, imaj datası dahil
       *
       * @param sourceFile
       * @returns {Array}
       */
      ExifRestorer.slice2Segments = function (sourceFile) {
        var rawImageArray = this.decode64(sourceFile);
        var head = 0,
          segments = [];

        while (1) {
          // Marker'lar FF (255) ile başlar
          // FF DA ise SOS segmentine geldik, çık.
          if (rawImageArray[head] == 255 && rawImageArray[head + 1] == 218) break;

          // FF D8 ise SOI'dir, image datası yeni başlıyor demektir, pointer'ı bir sonraki marker'a set et (esasında bundan önce data varsa JPEG değildir ki?!?)
          if (rawImageArray[head] == 255 && rawImageArray[head + 1] == 216) {
            head += 2;
          } else {
            // Pointer'ın olduğu noktada marker olduğunu farz ediyoruz, marker 2 byte, sonraki iki byte segment'in boyutunu belirtir (boyutsuz marker'lar da var???)
            var length = rawImageArray[head + 2] * 256 + rawImageArray[head + 3];
            // length boyut byte'ları dahil segment'in uzunluğudur, boyut byte'ları head + 2'den başlar, üstüne length'i ekleyip sonunu buluyoruz.
            var endPoint = head + length + 2;
            // rawImage'ın ilgili kısmını segment'e kopyalıyoruz, bu da demektir ki imagex2 byte memory kullanılacak
            var seg = rawImageArray.slice(head, endPoint);
            segments.push(seg);
            // Pointer'ı bir sonraki marker'a gönder
            head = endPoint;
          }

          // Dosya sonuna geldiysek çık
          if (head > rawImageArray.length) break;
        }

        return segments;
      };

      /**
       * TODO: Remove thumbnail if we have
       *
       * Segment'lerden sadece APP1'i alır
       * @param segments
       * @returns {*}
       */
      ExifRestorer.getExifArray = function (segments) {
        // FF E1: APP1 segmentini alır. FIXME: iOS JFIF kullanır dolayısıyla APP0 lazım bize sanırım
        for (var x = 0; x < segments.length; x++) {
          if (segments[x][0] == 255 && segments[x][1] == 225) {
            return segments[x];
          }
        }
        return [];
      };


      /**
       * Exif datasını target dosyaya sokuşturur.
       * Eğer target dosyada ilgili segment varsa iki tane olacak demek bu (sanki)
       * Target dosya * 2 byte memory kullanır
       *
       * @param targetFile
       * @param exifArray
       * @returns {Uint8Array}
       */
      ExifRestorer.insertExif = function (targetFile, exifArray) {
        var buf = this.decode64(targetFile);

        // İlk 4 byte JPEG tanımı: FFD8 FFEn, bu 4 marker'dan sonraki ilk marker'dan target dosyayı ikiye ayırır
        var separatePoint = buf.indexOf(255, 3);
        // İlk marker'dan öncesi
        var mae = buf.slice(0, separatePoint);
        // İlk marker'dan sonrası
        var ato = buf.slice(separatePoint);

        // Output'u generate etmeye başlıyoruz, dosya başını koy
        var array = mae;
        // Exif datasını ekle
        array = array.concat(exifArray);
        // Kalan datayı ekle
        array = array.concat(ato);

        return new Uint8Array(array);
      };

      /**************************************
       * Base64 encode/decode fonksiyonları
       **************************************/

      ExifRestorer.KEY_STR = "ABCDEFGHIJKLMNOP" +
      "QRSTUVWXYZabcdef" +
      "ghijklmnopqrstuv" +
      "wxyz0123456789+/" +
      "=";

      ExifRestorer.encode64 = function (input) {
        var output = "",
          chr1, chr2, chr3 = "",
          enc1, enc2, enc3, enc4 = "",
          i = 0;

        do {
          chr1 = input[i++];
          chr2 = input[i++];
          chr3 = input[i++];

          enc1 = chr1 >> 2;
          enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
          enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
          enc4 = chr3 & 63;

          if (isNaN(chr2)) {
            enc3 = enc4 = 64;
          } else if (isNaN(chr3)) {
            enc4 = 64;
          }

          output = output +
          this.KEY_STR.charAt(enc1) +
          this.KEY_STR.charAt(enc2) +
          this.KEY_STR.charAt(enc3) +
          this.KEY_STR.charAt(enc4);
          chr1 = chr2 = chr3 = "";
          enc1 = enc2 = enc3 = enc4 = "";
        } while (i < input.length);

        return output;
      };


      /**
       * TODO: Use ArrayBuffer or Uint8Array for "buf"
       *
       * @param input
       * @returns {Array}
       */
      ExifRestorer.decode64 = function (input) {
        input = input.replace("data:image/jpeg;base64,", "");
        var chr1, chr2, chr3 = "",
          enc1, enc2, enc3, enc4 = "",
          i = 0,
          buf = [];

        // remove all characters that are not A-Z, a-z, 0-9, +, /, or =
        var base64test = /[^A-Za-z0-9\+\/\=]/g;
        if (base64test.exec(input)) {
          alert("There were invalid base64 characters in the input text.\n" +
          "Valid base64 characters are A-Z, a-z, 0-9, '+', '/',and '='\n" +
          "Expect errors in decoding.");
        }
        input = input.replace(/[^A-Za-z0-9\+\/\=]/g, "");

        do {
          enc1 = this.KEY_STR.indexOf(input.charAt(i++));
          enc2 = this.KEY_STR.indexOf(input.charAt(i++));
          enc3 = this.KEY_STR.indexOf(input.charAt(i++));
          enc4 = this.KEY_STR.indexOf(input.charAt(i++));

          chr1 = (enc1 << 2) | (enc2 >> 4);
          chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
          chr3 = ((enc3 & 3) << 6) | enc4;

          buf.push(chr1);

          if (enc3 != 64) {
            buf.push(chr2);
          }
          if (enc4 != 64) {
            buf.push(chr3);
          }

          chr1 = chr2 = chr3 = "";
          enc1 = enc2 = enc3 = enc4 = "";

        } while (i < input.length);

        return buf;
      };


      return ExifRestorer;
    })();

    return ExifRestorer;
  });