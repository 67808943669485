angular.module('mavibayrak')
  .factory('AuthInterceptor', function ($rootScope, $q, $window, $injector) {
    return {
      request: function (config) {
        config.headers = config.headers || {};
        if ($window.localStorage.token) {
          config.headers.Auth = 'Bearer ' + $window.localStorage.token;
        }
        return config;
      },
      responseError: function (response) {
        var $q = $injector.get('$q');
        if (response.status === 401) {
          delete $window.localStorage.token;
          var $state = $injector.get('$state');
          if (!$state.includes('login')) {
            $state.go('login');
          }
        } else if (response.status > 401) {
          $injector.get('$ionicPopup').alert({
            title: 'Hata Oluştu',
            template: 'Sunucu tarafından ' + response.status + ' kodlu hata rapor edildi.'
          });
        } else if (response.status == 0) {
          $injector.get('$ionicPopup').alert({
            title: 'Hata Oluştu',
            template: 'Sunucuya bağlanılamadı'
          });
        }
        return $q.reject(response);
      }
    };
  });