angular.module('mavibayrak')
  .factory('Marinas', function ($injector, $http, $localstorage, $q, MyCache, appConfig, Utils) {
    var marinas = [];

    return {
      populate: function (force) {
        if (navigator.onLine && (force || MyCache.check('lumarina', 120))) {
          return $http.get(appConfig.endpoint('marinas')).then(function (response) {
            marinas = response.data;
            $localstorage.setObject('marinasData', marinas);
            MyCache.save('lumarina');
            return marinas;
          });
        } else {
          marinas = $localstorage.getObject('marinasData');
          return $q.when(marinas);
        }
      },
      all: function () {
        return marinas;
      },
      get: function (id) {
        for (var i = 0; i < marinas.length; i++) {
          var marina = marinas[i];
          if (marina.id == id) {
            return marina;
          }
        }
        return null;
      },
      getCities: function () {
        var cities = $injector.get('Cities');
        var marinaCities = [];
        marinas.map(function (element) {
          var city = cities.get(element.city_id);
          if (marinaCities.indexOf(city) == -1) {
            marinaCities.push(city);
          }
        });

        marinaCities.sort(Utils.trSorter);
        return marinaCities;
      },
      getTownsOfCity: function (cityId) {
        var towns = $injector.get('Towns');
        var marinaTowns = [];

        marinas.map(function (element) {
          if (element.city_id != cityId) return;
          var town = towns.get(element.town_id);
          if (marinaTowns.indexOf(town) == -1) {
            marinaTowns.push(town);
          }
        });

        marinaTowns.sort(Utils.trSorter);
        return marinaTowns;
      }
    };
  });