angular.module('mavibayrak')
  .controller('YachtCtrl', function ($scope, $stateParams, $state, $ionicPopup, $ionicHistory,
                                     Yachts, Cities, Towns, Audits, Marinas, Utils) {
    var audit, action = 'update';

    // Edit
    if ($stateParams.auditId) {
      audit = Audits.getStored($stateParams.auditId);
      if (!audit) {
        $ionicHistory.nextViewOptions({historyRoot: true});
        $ionicPopup.alert({ template: 'Kayıt bulunamadı. id: ' + $stateParams.auditId})
          .then(function () { $state.go('tab.yachts'); });
        return;
      }
      audit = Audits.getStored($stateParams.auditId);
    // New
    } else {
      // Tek draft kayıt saklanır, onu alalım
      audit = Audits.getDraft('yacht');
      // Kayıtlı draft varsa ve farklı bir yata aitse silelim
      if (audit && audit.yacht.id != $stateParams.yachtId) {
        Audits.deleteUnsavedData('yacht');
        audit = null;
      }
      // Kayıtlı draft yoksa ya da önceki adımda sildiysek default data'ları oluşturalım
      if (!audit) {
        audit = Audits.newRecord();
        audit.yacht = Yachts.get($stateParams.yachtId);
        action = 'insert';
      }
    }

    // Convert created_at to date object.
    // toISOString timezone verisi içermez, ekleyelim
    var date = new Date(audit.audit.created_at);
    audit.audit.created_at = new Date(date.getTime() - (date.getTimezoneOffset() * 60000));

    $scope.audit = audit.audit;
    $scope.yacht = audit.yacht;
    $scope.yachtTypes = Yachts.types;
    $scope.classes = Yachts.classes;
    $scope.flags = Yachts.flags;
    $scope.tankTypes = Yachts.tankTypes;
    $scope.marinas = Marinas.all();
    $scope.city = Cities.get($scope.yacht.city_id);
    $scope.town = Towns.get($scope.yacht.town_id);
    $scope.methods = Audits.methods;
    $scope.types = Audits.types;

    $scope.gotoQuestions = function () {
      if (action == 'insert') {
        Audits.insert(audit);
      } else {
        Audits.update(audit.id, {
          audit: $scope.audit,
          yacht: $scope.yacht
        });
      }
      $state.go('tab.questions_yacht', {auditId: audit.id, page: 1});
    };

    $scope.cancelAudit = function () {
      Utils.cancelAudit(audit);
    };
  });