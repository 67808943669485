angular.module('mavibayrak')
  .factory('Validator', function (Questions, Audits, $ionicPopup, $q) {

    var isValid = function (audit, questions) {
      var deferred = $q.defer();
      if (!audit.answers) {
        deferred.reject(questions[0].question);
        return deferred.promise;
      }
      var answers = audit.answers.answers;
      var auditType = Audits.getType(audit);

      for (var i = 0; i < questions.length; i++) {
        var item = questions[i];
        if (item.entity != auditType) continue;
        if (audit.hidden.indexOf(parseInt(item.id)) != -1) continue;
        if (!answers[item.id]) {
          deferred.reject(item.question);
          return deferred.promise;
        }
      }

      deferred.resolve();
      return deferred.promise;
    };

    return {
      isValid: function (audit) {
        var questions = Questions.getAll();
        return isValid(audit, questions);
      },
      isPageValid: function (audit, page) {
        var type = Audits.getType(audit);
        var questions = Questions.get(type, page);

        return isValid(audit, questions);
      }
    };
  });