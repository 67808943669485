angular.module('mavibayrak')
  .controller('AuditTeamCtrl', function ($stateParams, $scope, $state, $ionicPopup, $http, $q, $ionicHistory,
                                         appConfig, Audits) {
    var audit = Audits.getStored($stateParams.auditId);
    if (!audit) {
      $ionicHistory.nextViewOptions({historyRoot: true});
      $ionicPopup.alert({ template: 'Kayıt bulunamadı. id: ' + $stateParams.auditId})
        .then(function () { $state.go('tab.beaches'); });
      return;
    }
    var defs = {
      beach: 'Plaj',
      yacht: 'Yat ve Marina',
      marina: 'Marina'
    };

    var type = Audits.getType(audit);
    $scope.typeDef = defs[type];
    $scope.team = [];
    $scope.staff = [];
    $scope.observerCount = type == 'yacht' ? 3:2;

    if (audit.auditTeam) {
      audit.auditTeam.map(function (item) {
        if (item.type == 'beach_staff') $scope.staff.push(item.name);
        else if (item.type == 'audit_team') $scope.team.push(item.name);
      });
    }

    $scope.source = function (keyword) {
      var deferred = $q.defer();

      Audits.populateTeam().then(function (data) {
        var result = data.map(function (item) {
          // Format data
          if (item.name_surname.toLowerCase().indexOf(keyword.toLowerCase()) === 0)
            return {text: item.name_surname};
        }).filter(function (item) {
          // filter out undefined entries caused by map
          return item != undefined;
        }).reduce(function (p, c) {
          // Unique. ng-repeat fails if array is not unique
          if (p.indexOf(c) < 0) p.push(c);
          return p;
        }, []);
        deferred.resolve(result);
      });

      return deferred.promise;
    };

    $scope.next = function () {
      var data1 = $scope.staff.map(function (item) {
        return {
          type: 'beach_staff',
          name: item
        }
      });
      var data2 = $scope.team.map(function (item) {
        return {
          type: 'audit_team',
          name: item
        }
      });

      var data = data1.concat(data2);
      if (data.length == 0) {
        $ionicPopup.alert({
          title: 'Hata',
          template: 'En az 1 kişi girilmelidir'
        });
        return;
      }

      Audits.update(audit.id, {
        auditTeam: data
      });
      $state.go('tab.evaluation_' + type, {
        auditId: audit.id
      });
    };
  });