(function(){
  "use strict";
  angular.module('mavibayrak', ['ionic'])
  .run(function ($ionicPlatform, AuthService, $rootScope, $state, $localstorage, $templateCache, $http, $q,
                 Audits, Beaches, Cities, Towns, Marinas, Questions, Yachts) {

    // Eski uygulamanın localstorage'a kaydettiklerini uçur
    $localstorage.clearOldAppData();

    $ionicPlatform.ready(function () {
      // Hide the accessory bar by default
      // (remove this to show the accessory bar above the keyboard for form inputs)
      if (window.cordova && window.cordova.plugins.Keyboard) {
        cordova.plugins.Keyboard.hideKeyboardAccessoryBar(true);
      }
      if (window.StatusBar) {
        // org.apache.cordova.statusbar required
        StatusBar.styleDefault();
      }
    });

    $rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {
      if (toState.name != 'login' && !AuthService.isAuthenticated()) {
        $state.go("login");
        event.preventDefault();
      } else if (toState.name == 'login' && AuthService.isAuthenticated()) {
        $state.go('tab.beaches');
        event.preventDefault();
      }
    });

    $rootScope.updateLocalstorageUsage = function () {
      function bytesToSize(bytes) {
        if(bytes == 0) return '0 Byte';
        var k = 1000;
        var sizes = ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        var i = Math.floor(Math.log(bytes) / Math.log(k));
        return (bytes / Math.pow(k, i)).toPrecision(3) + ' ' + sizes[i];
      }

      $rootScope.localstorageUsage = bytesToSize($localstorage.usage());
    };

    $rootScope.$on('localstorageUpdated', $rootScope.updateLocalstorageUsage);

    $rootScope.updateLocalstorageUsage();

    $rootScope.refreshing = false;
    $rootScope.refreshSources = function () {
      if (!navigator.onLine) {
        return;
      }

      $rootScope.refreshing = true;
      var promises = [
        Audits.populateTeam(true),
        Audits.populateEmails(true),
        Beaches.populate(true),
        Cities.populate(true),
        Towns.populate(true),
        Marinas.populate(true),
        Questions.populate(true),
        Yachts.populate(true)
      ];
      $q.all(promises).finally(function () {
        $rootScope.refreshing = false;
      });
    };

    if (navigator.onLine) {
      var templates = [
        'templates/audit-team.html',
        'templates/beach.html',
        'templates/emails.html',
        'templates/evaluation.html',
        'templates/login.html',
        'templates/marina.html',
        'templates/multiselect.html',
        'templates/questions.html',
        'templates/tab-beaches.html',
        'templates/tab-marinas.html',
        'templates/tab-yachts.html',
        'templates/tabs.html',
        'templates/yacht.html'
      ];

      templates.map(function (template) {
        if ($templateCache.get(template)){
          return; //prevent the prefetching if the template is already in the cache
        }
        $http.get(template).success(function (t) {
          $templateCache.put(template, t);
        });
      });
    }

  });
})();
