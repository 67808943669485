angular.module('mavibayrak')
  .factory('MyCache', function ($localstorage) {
    return {
      /**
       *
       * @param key
       */
      save: function (key) {
        $localstorage.set(key, Date.now());
      },

      /**
       *
       * @param key
       * @param expiration in minutes
       */
      check: function (key, expiration) {
        var cacheVal = $localstorage.get(key);
        if (!cacheVal) return true;

        return (Date.now() - cacheVal) > 1000 * 60 * expiration;
      }
    }
  });