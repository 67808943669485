angular.module('mavibayrak')
  .directive('autoComplete', function ($ionicPopover, $rootScope, $q, $timeout) {
    // Keyboard input delay (ms)
    var delay = 600;

    var template = '<ion-popover-view><ion-content>' +
      '<div class="list"><div style="cursor: pointer;" class="item" ng-repeat="item in items" ng-click="select(item)">{{item.text}}</div></div>' +
      '</ion-content></ion-popover-view>';

    return {
      require: 'ngModel',
      restrict: 'A',
      link: function (scope, el, attrs, ngModelCtrl) {
        var popoverScope = $rootScope.$new(true);
        popoverScope.popover = $ionicPopover.fromTemplate(template, {
          scope: popoverScope
        });

        var timeoutPromise;
        el.on('keyup', function (event) {
          if (timeoutPromise) $timeout.cancel(timeoutPromise);

          if (!ngModelCtrl.$modelValue) {
            popoverScope.popover.hide();
            return;
          }

          timeoutPromise = $timeout(function () {
            $q.when(scope.$eval(attrs.acSource)).then(function (items) {
              popoverScope.items = items;
              if (items.length > 0) {
                popoverScope.popover.show(event);
              } else {
                popoverScope.popover.hide();
              }
            });
          }, delay);
        });

        popoverScope.select = function (item) {
          ngModelCtrl.$setViewValue(item.text);
          ngModelCtrl.$render();
          popoverScope.popover.hide();
        };

        el.on('$destroy', function () {
          // TODO: Remove if popover template is not removed already
          // TODO: Cancel attrs.acSource if it's http request or some other promise
          if (timeoutPromise) $timeout.cancel(timeoutPromise);
          popoverScope.$destroy();
        });
      }
    }
  });