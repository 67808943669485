angular.module('mavibayrak')
  .constant('appConfig', {
    endpoint: function (url) {
      if (location.hostname == 'localhost' || location.hostname == '0.0.0.0') {
        return 'https://mavibayrak.test/api/' + url + '?XDEBUG_SESSION_START=PHPSTORM';
      }

      if (location.hostname == 'demo.alfatron.com.tr') {
        return 'https://' + location.hostname + '/mavibayrak/yonet/api/' + url
      }

      return 'https://' + location.hostname + '/yonet/api/' + url;
    }
  });