angular.module('mavibayrak')
  .factory('AuthService', function ($localstorage, $http, $q, $ionicLoading, $state, appConfig) {
    return {
      isAuthenticated: function () {
        // See: https://auth0.com/blog/2014/01/07/angularjs-authentication-with-cookies-vs-token/
        return $localstorage.get('token', '').length > 0;
      },
      login: function (username, passwd) {
        var deferred = $q.defer();
        if (!navigator.onLine) {
          deferred.reject('İlk giriş için internet bağlantısı gereklidir');
          return deferred.promise;
        }

        $ionicLoading.show({
          template: 'Yükleniyor...'
        });

        $http.post(appConfig.endpoint('login'), {
          user: username,
          pwd: passwd
        }).success(function (data) {
          $localstorage.set('token', data.sessionToken);
          $localstorage.set('email', data.email);
          deferred.resolve();
        }).error(function (data) {
          $localstorage.remove('token');
          deferred.reject(data ? data.message : null);
        }).finally(function () {
          $ionicLoading.hide();
        });
        return deferred.promise;
      },
      logout: function () {
        $localstorage.remove('token');
        $state.go('login');
      }
    }
  });