angular.module('mavibayrak')
  .factory('$localstorage', function ($window, $rootScope, $ionicPopup) {
    var lengthInUtf8Bytes = function (str) {
      // Matches only the 10.. bytes that are non-initial characters in a multi-byte sequence.
      var m = encodeURIComponent(str).match(/%[89ABab]/g);
      return str.length + (m ? m.length : 0);
    };

    var handleQuotaExceededError = function () {
      $ionicPopup.alert({
        title: 'Hata',
        template: 'Bilgiler kaydedilemedi, cihaz (tarayıcı) hafızasında yeterli yer yok.'
      });
    };

    return {
      // MaviBayrak'a özgü metot! Eski application'ın set ettiği localStorage data'sını siler
      clearOldAppData: function () {
        for (var item in $window.localStorage) {
          //noinspection JSUnresolvedFunction
          if (!$window.localStorage.hasOwnProperty(item)) continue;
          if (item.indexOf('http://denetim') != -1) {
            // 0ed0574a-6d07-44f6-9758-418351df45a3--http://denetim.mavibayrak.org.tr-beaches
            delete $window.localStorage[item];
          } else if (/audits-\w+-/.test(item)) {
            // audits-0ed0574a-6d07-44f6-9758-418351df45a3
            delete $window.localStorage[item];
          }
        }

        if ($window.localStorage.audits && $window.localStorage.audits.indexOf('[') == -1) {
          delete $window.localStorage.audits;
        }
      },
      clearAll: function () {
        $window.localStorage.clear();
      },
      set: function (key, value) {
        try {
          $window.localStorage[key] = value;
          $rootScope.$broadcast('localstorageUpdated');
        } catch (e) {
          if (e.code != 22) throw e;
          handleQuotaExceededError(key);
          return false;
        }
        return true;
      },
      get: function (key, defaultValue) {
        return $window.localStorage[key] || defaultValue;
      },
      remove: function (key) {
        delete $window.localStorage[key];
      },
      setObject: function (key, value) {
        try {
          $window.localStorage[key] = JSON.stringify(value);
          $rootScope.$broadcast('localstorageUpdated');
        } catch (e) {
          if (e.code != 22) throw e;
          handleQuotaExceededError(key);
          return false;
        }
        return true;
      },
      getObject: function (key) {
        if (!$window.localStorage[key]) return null;

        // JSON.stringify date objelerinin string notasyonunu saklar, Date objesine çevirelim.
        var dateTimeRegExp = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)Z$/;
        return JSON.parse($window.localStorage[key], function (k, v) {
          if (typeof v == 'string' && dateTimeRegExp.test(v)) {
            return new Date(v);
          }
          return v;
        });
      },
      usage: function() {  // provide the size in bytes of the data currently stored
        var size = 0;
        for (var i = 0; i <= $window.localStorage.length - 1; i++) {
          var key = $window.localStorage.key(i);
          size += lengthInUtf8Bytes($window.localStorage.getItem(key));
        }
        return (Math.floor(size * 100)) / 100;
      },
      testCapacity: function() {
        // fill up all data in local storage to see how much we can squeeze in
        $window.localStorage.clear();
        var maxMBToTest = 1024 * 1024 * 20;
        var i = 0;
        var testPacket = new Array(1025).join("a"); // create 1024 characters so 1KB
        while (i < maxMBToTest) { // MB level
          var t = 0;
          while (t < 1025) { // KB level
            try {
              $window.localStorage.setItem(i + "|" + t, testPacket);
            } catch (error) {
              var kbsaved = Math.floor(((t / 1024) * 100)); // calculate percentage of 1024
              var storeSpace = i + '.' + kbsaved; // add MB and KB values
              storeSpace = (Math.floor(storeSpace * 100)) / 100; // rounds down the value
              t = 1025;
              i = maxMBToTest + 1;
            }
            t++;
          }
          i++;
        }
        $window.localStorage.clear(); // clear all local storage
        return storeSpace;
      }
    }
  });
