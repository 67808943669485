angular.module('mavibayrak')
  .controller('LoginCtrl', function ($scope, $state, $ionicPopup, $ionicLoading, $q, AuthService, Audits, Beaches, Cities, Towns, Marinas, Questions, Yachts) {
    $scope.form = {};
    $scope.login = function () {
      //noinspection JSUnresolvedVariable
      AuthService
        .login($scope.form.username, $scope.form.password)
        .then(function () {
          $scope.form.username = '';
          $scope.form.password = '';

          $ionicLoading.show({
            template: 'Veriler güncelleniyor...'
          });

          var promises = [
            Audits.populateTeam(true),
            Audits.populateEmails(true),
            Beaches.populate(true),
            Cities.populate(true),
            Towns.populate(true),
            Marinas.populate(true),
            Questions.populate(true),
            Yachts.populate(true)
          ];
          $q.all(promises).then(function () {
            $state.go('tab.beaches');
          }).finally(function () {
            $ionicLoading.hide();
          });
        }, function (errMsg) {
          if (errMsg) {
            $ionicPopup.alert({
              title: 'Giriş Başarısız',
              template: errMsg
            });
          }
        });
    }
  });