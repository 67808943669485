angular.module('mavibayrak')
  .factory('Cities', function ($http, $localstorage, $q, MyCache, appConfig) {
    var cities;

    return {
      populate: function (force) {
        if (navigator.onLine && (force || MyCache.check('lucity', 120))) {
          return $http.get(appConfig.endpoint('cities')).then(function (response) {
            cities = response.data;
            $localstorage.setObject('citiesData', cities);
            MyCache.save('lucity');
            return response.data;
          });
        } else {
          cities = $localstorage.getObject('citiesData');
          return $q.when(cities);
        }
      },

      all: function () {
        return cities;
      },
      get: function (id) {
        var city;
        cities.forEach(function (element) {
          if (element.id == id) {
            city = element;
            return false;
          }
        });

        return city;
      }
    };
  });