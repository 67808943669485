angular.module('mavibayrak')
  .controller('EvaluationCtrl', function ($stateParams, $scope, $state, $ionicPopup, $ionicHistory, Audits, Questions) {

    var audit = Audits.getStored($stateParams.auditId);
    if (!audit) {
      $ionicHistory.nextViewOptions({historyRoot: true});
      $ionicPopup.alert({ template: 'Kayıt bulunamadı. id: ' + $stateParams.auditId})
        .then(function () { $state.go('tab.beaches'); });
      return;
    }
    $scope.audit = audit.audit;
    $scope.results = Audits.results;
    $scope.evalNotes = [];

    var answers = audit.answers;

    for (var questionId in answers.notes) {
      if (!answers.notes.hasOwnProperty(questionId)) continue;
      $scope.evalNotes.push({
        question: Questions.getById(questionId),
        note: answers.notes[questionId]
      });
    }

    $scope.next = function () {
      if (!$scope.audit.result) {
        $ionicPopup.alert({
          title: 'Hata',
          template: 'Nihai karar girilmelidir'
        });
        return;
      }

      var notes = {};
      $scope.evalNotes.map(function (noteRow) {
        notes[noteRow.question.id] = noteRow.note
      });
      Audits.update(audit.id, {
        audit: {
          result: $scope.audit.result,
          notes: $scope.audit.notes
        },
        answers: {
          notes: notes
        }
      });
      $state.go('tab.emails_' + Audits.getType(audit), {
        auditId: audit.id
      });
    };
  });

