angular.module('mavibayrak')
  .factory('Yachts', function ($injector, $http, $localstorage, $q, appConfig, Utils, MyCache) {
    var yachts = [];

    var types = ['Yolcu Motoru', 'Charter', 'Motor Yat', 'Diğer'];
    var classes = ['Ticari', 'Özel'];
    var flags = ['TC', 'Yabancı'];
    var tankTypes = {
      'separate': 'Ayrı Tanklarda',
      'combined': 'Birleşik Tanklarda'
    };

    return {
      types: types,
      classes: classes,
      flags: flags,
      tankTypes: tankTypes,
      populate: function (force) {
        if (navigator.onLine && (force || MyCache.check('luyacht', 120))) {
          return $http.get(appConfig.endpoint('yachts')).then(function (response) {
            yachts = response.data;
            $localstorage.setObject('yachtsData', yachts);
            MyCache.save('luyacht');
            return yachts;
          });
        } else {
          yachts = $localstorage.getObject('yachtsData');
          return $q.when(yachts);
        }
      },
      all: function () {
        return yachts;
      },
      get: function (id) {
        var yacht;
        yachts.forEach(function (element) {
          if (element.id == id) {
            yacht = element;
            return false;
          }
        });

        return yacht;
      },
      getCities: function () {
        var cities = $injector.get('Cities');
        var yachtCities = [];
        yachts.map(function (element) {
          var city = cities.get(element.city_id);
          if (yachtCities.indexOf(city) == -1) {
            yachtCities.push(city);
          }
        });

        yachtCities.sort(Utils.trSorter);
        return yachtCities;
      },
      getTownsOfCity: function (cityId) {
        var towns = $injector.get('Towns');
        var yachtTowns = [];

        yachts.map(function (element) {
          if (element.city_id != cityId) return;
          var town = towns.get(element.town_id);
          if (yachtTowns.indexOf(town) == -1) {
            yachtTowns.push(town);
          }
        });

        yachtTowns.sort(Utils.trSorter);
        return yachtTowns;
      }
    };
  });