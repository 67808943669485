angular.module('mavibayrak')
  .factory('Beaches', function ($injector, $http, $localstorage, $q, MyCache, appConfig, Utils) {
    var beaches = [];

    return {
      classes: ['Tesis Plajı', 'Halk Plajı', 'Beach Club', 'İkinci Konut Plajı'],
      populate: function (force) {
        if (navigator.onLine && (force || MyCache.check('lubeach', 120))) {
          return $http.get(appConfig.endpoint('beaches')).then(function (response) {
            beaches = response.data;
            $localstorage.setObject('beachesData', beaches);
            MyCache.save('lubeach');
            return response.data;
          });
        } else {
          beaches = $localstorage.getObject('beachesData');
          return $q.when(beaches);
        }
      },
      all: function () {
        return beaches;
      },
      get: function (id) {
        for (var i = 0; i < beaches.length; i++) {
          var beach = beaches[i];
          if (beach.id == id) {
            return beach;
          }
        }
        return null;
      },
      getCities: function () {
        var cities = $injector.get('Cities');
        var beachCities = [];
        beaches.map(function (element) {
          var city = cities.get(element.city_id);
          if (beachCities.indexOf(city) == -1) {
            beachCities.push(city);
          }
        });

        beachCities.sort(Utils.trSorter);
        return beachCities;
      },
      getTownsOfCity: function (cityId) {
        var towns = $injector.get('Towns');
        var beachTowns = [];

        beaches.map(function (element) {
          if (element.city_id != cityId) return;
          var town = towns.get(element.town_id);
          if (beachTowns.indexOf(town) == -1) {
            beachTowns.push(town);
          }
        });

        beachTowns.sort(Utils.trSorter);
        return beachTowns;
      }
    };
  });