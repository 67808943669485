angular.module('mavibayrak')
  .controller('EmailsCtrl', function ($stateParams, $scope, $ionicPopup, $ionicHistory, $localstorage, $http, $state, $q, appConfig,
                                      Audits, Questions, Beaches, Marinas, Yachts, Validator) {
    var audit = Audits.getStored($stateParams.auditId);
    if (!audit) {
      $ionicHistory.nextViewOptions({historyRoot: true});
      $ionicPopup.alert({ template: 'Kayıt bulunamadı. id: ' + $stateParams.auditId})
        .then(function () { $state.go('tab.beaches'); });
      return;
    }
    var auditType = Audits.getType(audit);

    var getDefaults = function () {
      var defaults = [$localstorage.get('email')];

      switch(auditType) {
        case 'beach':
          var beach = audit.beach;
          if (beach.responsible1_email) defaults.push(beach.responsible1_email);
          if (beach.responsible2_email) defaults.push(beach.responsible2_email);
          if (beach.manager_email) defaults.push(beach.manager_email);
          break;

        case 'yacht':
          var yacht = audit.yacht;
          if (yacht.responsible_email) defaults.push(yacht.responsible_email);
          break;

        case 'marina':
          var marina = audit.marina;
          if (marina.responsible_email) defaults.push(marina.responsible_email);
          if (marina.manager_email) defaults.push(marina.manager_email);
          break;
      }

      return defaults;
    };


    $scope.emails = audit.emails && audit.emails.length > 0 ? audit.emails : getDefaults();

    $scope.save = function () {
      var invalidEmails = [];
      audit.emails = $scope.emails.filter(function (item) {
        if (!item) return false;
        if (!item.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
          invalidEmails.push(item);
        }
        return item;
      });

      if (invalidEmails.length > 0) {
        $ionicPopup.alert({
          title: 'Geçersiz e-posta adresi',
          template: invalidEmails.join('\n')
        });
        return;
      }

      Audits.update(audit.id, {
        emails: audit.emails
      });

      if (audit.emails.length == 0) {
        $ionicPopup.alert({
          title: 'Lütfen en az 1 e-posta adresi giriniz.'
        });
        return;
      }

      // Validation: Tüm sorular cevaplanmış mı?
      Validator.isValid(audit).then(
        function () {
          sendToServer($stateParams.auditId);
        },
        function (firstFailedQuestion) {
          $ionicPopup.alert({
            title: 'Tüm sorular cevaplanmadı',
            template: "'" + firstFailedQuestion + "' sorusu cevaplanmadı"
          });
        }
      );
    };

    $scope.autocompleteSource = function (keyword) {
      var deferred = $q.defer();

      Audits.populateEmails().then(function (data) {
        var result = data.map(function (item) {
          if (item.email.indexOf(keyword) === 0) return {text: item.email};
        }).filter(function (item) {
          // filter out undefined entries caused by map
          return item != undefined;
        }).reduce(function (p, c) {
          // Unique. ng-repeat fails if array is not unique
          if (p.indexOf(c) < 0) p.push(c);
          return p;
        }, []);
        deferred.resolve(result);
      });

      return deferred.promise;
    };

    var sendToServer = function (auditId) {
      var audit = Audits.getStored(auditId);

      var gotoFirstPage = function () {
        $ionicHistory.nextViewOptions({
          historyRoot: true
        });
        switch (auditType) {
          case 'beach':
            $state.go('tab.beaches');
            break;
          case 'yacht':
            $state.go('tab.yachts');
            break;
          case 'marina':
            $state.go('tab.marinas');
            break;
        }
      };

      if (navigator.onLine) {
        var loadingPopup = $ionicPopup.show({
          title: "Sunucu Bağlantısı Kuruluyor",
          template: 'Form bilgileri cihaza kaydedildi, sunucu ile senkronize edilirken lütfen bekleyiniz...'
        });
        Audits.sendToServer(audit).then(function () {
          $ionicPopup.alert({
            title: 'Kaydedildi',
            template: 'Bilgiler sunucuya kaydedildi'
          });
          gotoFirstPage();
        }).finally(function () {
          loadingPopup.close();
        });
      } else {
        Audits.setStatusSaved(audit);
        $ionicPopup.alert({
          title: 'Kaydedildi',
          template: 'Bilgiler cihaza kaydedildi.'
        });
        gotoFirstPage();
      }
    }

  });
